import {
  FETCH_DAILY_GOAL,
  FETCH_STATUS_LIST,
  FETCH_TRAYS,
  FETCH_TRAYS_TICKETS,
  GET_TICKET_BY_ID,
  FETCH_TICKET_HISTORY_LOG,
  GET_CURRENT_PROMISE,
  GET_PROMISES_BY_TICKET,
  GET_NEXT_TICKET,
  FETCH_STATUS_LIST_BY_TICKET,
  GET_CATALOG_ITEMS,
  PAYMENT_DELAY_CATALOG,
  PAYMENT_POSTPONED_CATALOG,
  TICKET_STATUS_CATALOG,
  GET_AGENTS_MONITORING,
  MANAGEMENT_COLLECTIONS_REPORT,
  GET_TOTAL_CLIENTS,
  SET_FILTERS
} from './actions'

const initialState = {
  dailyGoal: null,
  statusList: null,
  trays: null,
  currentPromise: null,
  ticketData: undefined,
  filters: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DAILY_GOAL:
      return { ...state, dailyGoal: action.payload }
    case FETCH_STATUS_LIST:
      return { ...state, statusList: action.payload }
    case FETCH_STATUS_LIST_BY_TICKET:
      return { ...state, ticketStatusList: action.payload }
    case FETCH_TRAYS:
      return { ...state, trays: action.payload }
    case GET_TOTAL_CLIENTS:
      return { ...state, totalClients: action.payload }
    case FETCH_TRAYS_TICKETS:
      return { ...state, traysTickets: action.payload }
    case GET_TICKET_BY_ID:
      return { ...state, ticketData: action.payload }
    case FETCH_TICKET_HISTORY_LOG:
      return { ...state, ticketHistory: action.payload }
    case GET_CURRENT_PROMISE:
      return { ...state, currentPromise: action.payload }
    case GET_PROMISES_BY_TICKET:
      return { ...state, promiseHistory: action.payload }
    case GET_NEXT_TICKET:
      return { ...state, nextTicket: action.payload }
    case GET_CATALOG_ITEMS:
      return { ...state, catalog: action.payload }
    case PAYMENT_DELAY_CATALOG:
      return { ...state, paymentDelay: action.payload }
    case PAYMENT_POSTPONED_CATALOG:
      return { ...state, postponedPayment: action.payload }
    case TICKET_STATUS_CATALOG:
      return { ...state, ticketStatus: action.payload }
    case GET_AGENTS_MONITORING:
      return { ...state, agentsMonitoring: action.payload }
    case MANAGEMENT_COLLECTIONS_REPORT:
      return { ...state, managementCollectionsReport: action.payload }
    case SET_FILTERS:
      return { ...state, filters: action.payload }
    default:
      return state
  }
}
